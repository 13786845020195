<template>
  <jrv-pick-streamlined
    v-slot="{ jrv }"
    title="Envío de Actas"
    nav1="ACTAS"
    noJrv="Escoja una JRV del desplegable superior para enviar las fotos de sus actas."
  >
  
    <div v-if="jrv">

        <div class="lista-incidencias">
          <ion-list>
            <ion-item
              v-for="i in ud('convocatoriasOrden')"
              :key="i"
              class="incidencia"
            >
              <ion-grid>
                <ion-row>
                  <ion-col size="12" class="nombre">{{
                    convocatorias[i]
                  }}</ion-col>
                </ion-row>
                <ion-row>
                  <ion-col size="12" size-sm >
                    <ion-button expand="block" @click="enviarFoto(jrv, i)">
                      <!-- eslint-disable vue/no-deprecated-slot-attribute -->
                      <ion-icon slot="start" :icon="attach"></ion-icon>
                      ENVIAR IMAGEN
                    </ion-button>
                  </ion-col>
                  <ion-col size="12" size-sm v-if="estadoFoto[jrv][i] && estadoFoto[jrv][i].length">
                    <ion-button expand="block" @click="verFotos(jrv,i)">
                      <!-- eslint-disable vue/no-deprecated-slot-attribute -->
                      <ion-icon slot="start" :icon="imagesOutline"></ion-icon>

                      VER {{ estadoFoto[jrv][i].length }}
                      {{ estadoFoto[jrv][i].length == 1 ? "IMAGEN" : "IMÁGENES" }}
                    </ion-button>
                  </ion-col>
                  <ion-col size="12" size-sm v-if="imageQueue[jrv][i] && imageQueue[jrv][i].length">
                    <ion-button
                      expand="block"
                      color="warning"
                      @click="reenviarCola(jrv,i)"
                    >
                      <!-- eslint-disable vue/no-deprecated-slot-attribute -->
                      <ion-icon
                        slot="start"
                        :icon="cloudUploadOutline"
                      ></ion-icon>

                      REINTENTAR ENVÍO ({{imageQueue[jrv][i].length}})
                    </ion-button>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-item>
          </ion-list>
        </div>



    </div>
  </jrv-pick-streamlined>
</template>

<script lang="ts">
import store from "@/modules/adaptedStorage";
import JrvPickStreamlined from "@/components/JrvPickStreamlined.vue";
import { defineComponent } from "vue";
import cyanGetPhoto from '@/modules/cyanGetPhoto'

import {
  attach,
  imagesOutline,
  alertCircleOutline,
  cloudUploadOutline
} from "ionicons/icons";
import {
    IonList,
  IonIcon,
  IonButton,
  IonRow,
  IonItem,
  IonCol,
  IonGrid,
  alertController,

} from '@ionic/vue';

import { territorios } from "@/modules/cyanRegions";
import cyanRequest from "@/modules/cyanRequest";
import ud from "@/modules/cyanUserData";

export default defineComponent({
  name: "CierreJrvStreamlined",
  components: {
    JrvPickStreamlined,
    IonList,
  IonIcon,
  IonButton,
  IonRow,
  IonCol,
  IonItem,
  IonGrid,
  },
  setup() {
    return {
      attach,
      imagesOutline,
      alertCircleOutline,
      cloudUploadOutline,
      ud,
    };
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    estadoFoto() {
      return ud('estadoFoto')
    },
    imageQueue() {
      const iq = store.state.imageQueue;
      const niq = {} as any;
      const jrv = ud('jrv');
      const con = ud('convocatoriasOrden');

      for (let n = 0; n != jrv.length; n++) {
        const x = {} as any;
        const idJrv = jrv[n];
        for (let c = 0; c != con.length; c++) {
          const idCnv = con[c];
          x[idCnv] = (iq[idJrv] && iq[idJrv][idCnv]) ? iq[idJrv][idCnv] : null;
        }
        niq[idJrv] = x;
      }
      return niq;
    },
    ambito() {
      const n = this.datosCentro.nivel as string;
      if (!(n in territorios)) return "";
      return territorios[n].nombre + ", " + territorios[n].nombrePadre;
    },
    datosCentro() {
      return ud("centro", { nivel: "SV", nombre: "Desconocido" });
    },

    convocatorias() {
      return ud('convocatorias');
    }

  },
  methods: {

    async enviarFoto(jrv: any, convocatoria: any) {
      const image = await cyanGetPhoto();

      if (image === false) {
        return;
      }

      cyanRequest("jrsl/foto/" + jrv+'/'+convocatoria, {
        isPost: true,
        retries: 3,
        files: { fichero: image },
        isModal: "Enviando imagen...",
        needsAuth: true,
        silent: true
      }).then(function (d) {
        if (d.ok) {
          store.commit("patchUserData", d);
        } else {
          // alert

          alertController
          .create({
            message: "Hubo un error al subir la imagen. La imagen se ha almacenado para poder reintentar el envío más adelante.",
            buttons: ["OK"],
          })
          .then((alert) => alert.present());

          // meter a la cola

          store.commit('queueImageActa',{
            junta: jrv,
            convocatoria: convocatoria,
            imagen: image
          });
        }
      });
    },
    async reenviarCola(jrv: any, convocatoria: any) {
      const iq = this.imageQueue[jrv][convocatoria];
      
      if (!iq || !iq.length) return;

      const newIq = [] as any;
      let subidas = 0;

      for (let n = 0; n != iq.length; n++) newIq.push(iq[n]);
      for (let n = 0; n != newIq.length; n++) {
      await cyanRequest("jrsl/foto/" + jrv+'/'+convocatoria, {
        isPost: true,
        retries: 3,
        files: { fichero: newIq[n].imagen },
        isModal: "Reintentando envío " + (n+1) + ' de ' +newIq.length + '...',
        needsAuth: true,
        silent: true
      }).then(function (d) {
        if (d.ok) {
          store.commit("patchUserData", d);
          store.commit("dequeueImageActa", { junta: jrv, convocatoria: convocatoria, serial: newIq[n].serial });
          subidas++;
        }
      });

      }

      alertController
        .create({
          message: (iq.length == subidas) ?  "Se subieron todas las imágenes pendientes." : ((subidas == 0) ? "No se pudieron subir las imágenes pendientes." : "Se subieron algunas de las imágenes pendientes, pero aún quedan algunas por subir."),
          buttons: ["OK"],
        })
        .then((alert) => alert.present());
      
    },
    verFotos(jrv: any, convocatoria: any) {
      const result = [];
      const medios = ud('estadoFoto')[jrv][convocatoria];
      if (!medios || !medios.length) return;
      let counter = 1;
      for (const i of medios) {
        result.push({
          src: i.url,
          title: "Imagen " + counter++ + " de " + medios.length,
        });
      }

      if (result.length) {
        store.commit("setLightboxImages", result);
        store.commit("setLightboxVisible", true);
      }
    },
  },
});
</script>

<style scoped>

.lista-incidencias {
  margin-top: 1em;
}
.nombre {
  font-weight: 500;
  font-size: 120%;
  color: var(--ion-color-primary);
  text-align: center;
}
.dato {
  color: var(--cyan-dark-grey);
  font-weight: 500;
  padding-left: 1.6em;
  text-indent: -1.6em;
}
.dato span {
  color: var(--ion-color-primary);
  font-weight: normal;
}
.incidencia {
  margin: 5px 0;
  border: 1px solid var(--cyan-light-grey);
  border-radius: 15px;
  background: white;
  padding: 0 5px;
}
</style>