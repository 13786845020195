
import CyanPageWrapper from "@/components/CyanPageWrapper.vue";
import { defineComponent } from "vue";
import { IonPage, IonSelect, IonSelectOption } from "@ionic/vue";

import {
  attach,
  imagesOutline,
  alertCircleOutline,
  checkmarkCircleOutline,
} from "ionicons/icons";

import { territorios } from "@/modules/cyanRegions";
import ud from "@/modules/cyanUserData";

export default defineComponent({
  name: "JrvPickStreamlined",
  components: {
    IonPage,
    CyanPageWrapper,
    IonSelect,
    IonSelectOption,
  },
  setup() {
    return {
      attach,
      imagesOutline,
      alertCircleOutline,
      checkmarkCircleOutline,
    };
  },
  data() {
    return {
      jrv: "",
    };
  },
  computed: {
    ambito() {
      const n = this.datosCentro.nivel as string;
      if (!(n in territorios)) return "";
      return territorios[n].nombre + ", " + territorios[n].nombrePadre;
    },
    datosCentro() {
      return ud("centro", { nivel: "SV", nombre: "Desconocido" });
    },

    incidencias(): any {
      return ud("incidencias", []);
    },
    listaJrv(): any {
      return ud("jrv");
    },
    nombres(): any {
      return this.nombreJrv ?? ud("nombreJrv");
    }
  },
  methods: {
    updateSelect() {
      const e = document.querySelector("ion-select");
      if (!e) return;

      e.setAttribute('disabled',"1");
      e.removeAttribute('disabled');
    },

  },
  props: {
    title: String,
    backRoute: String,
    nav1: {
      type: String,
    },
    noJrv: String,
    nombreJrv: Object,
  },
});
