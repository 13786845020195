
import store from "@/modules/adaptedStorage";
import JrvPickStreamlined from "@/components/JrvPickStreamlined.vue";
import { defineComponent } from "vue";
import cyanGetPhoto from '@/modules/cyanGetPhoto'

import {
  attach,
  imagesOutline,
  alertCircleOutline,
  cloudUploadOutline
} from "ionicons/icons";
import {
    IonList,
  IonIcon,
  IonButton,
  IonRow,
  IonItem,
  IonCol,
  IonGrid,
  alertController,

} from '@ionic/vue';

import { territorios } from "@/modules/cyanRegions";
import cyanRequest from "@/modules/cyanRequest";
import ud from "@/modules/cyanUserData";

export default defineComponent({
  name: "CierreJrvStreamlined",
  components: {
    JrvPickStreamlined,
    IonList,
  IonIcon,
  IonButton,
  IonRow,
  IonCol,
  IonItem,
  IonGrid,
  },
  setup() {
    return {
      attach,
      imagesOutline,
      alertCircleOutline,
      cloudUploadOutline,
      ud,
    };
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    estadoFoto() {
      return ud('estadoFoto')
    },
    imageQueue() {
      const iq = store.state.imageQueue;
      const niq = {} as any;
      const jrv = ud('jrv');
      const con = ud('convocatoriasOrden');

      for (let n = 0; n != jrv.length; n++) {
        const x = {} as any;
        const idJrv = jrv[n];
        for (let c = 0; c != con.length; c++) {
          const idCnv = con[c];
          x[idCnv] = (iq[idJrv] && iq[idJrv][idCnv]) ? iq[idJrv][idCnv] : null;
        }
        niq[idJrv] = x;
      }
      return niq;
    },
    ambito() {
      const n = this.datosCentro.nivel as string;
      if (!(n in territorios)) return "";
      return territorios[n].nombre + ", " + territorios[n].nombrePadre;
    },
    datosCentro() {
      return ud("centro", { nivel: "SV", nombre: "Desconocido" });
    },

    convocatorias() {
      return ud('convocatorias');
    }

  },
  methods: {

    async enviarFoto(jrv: any, convocatoria: any) {
      const image = await cyanGetPhoto();

      if (image === false) {
        return;
      }

      cyanRequest("jrsl/foto/" + jrv+'/'+convocatoria, {
        isPost: true,
        retries: 3,
        files: { fichero: image },
        isModal: "Enviando imagen...",
        needsAuth: true,
        silent: true
      }).then(function (d) {
        if (d.ok) {
          store.commit("patchUserData", d);
        } else {
          // alert

          alertController
          .create({
            message: "Hubo un error al subir la imagen. La imagen se ha almacenado para poder reintentar el envío más adelante.",
            buttons: ["OK"],
          })
          .then((alert) => alert.present());

          // meter a la cola

          store.commit('queueImageActa',{
            junta: jrv,
            convocatoria: convocatoria,
            imagen: image
          });
        }
      });
    },
    async reenviarCola(jrv: any, convocatoria: any) {
      const iq = this.imageQueue[jrv][convocatoria];
      
      if (!iq || !iq.length) return;

      const newIq = [] as any;
      let subidas = 0;

      for (let n = 0; n != iq.length; n++) newIq.push(iq[n]);
      for (let n = 0; n != newIq.length; n++) {
      await cyanRequest("jrsl/foto/" + jrv+'/'+convocatoria, {
        isPost: true,
        retries: 3,
        files: { fichero: newIq[n].imagen },
        isModal: "Reintentando envío " + (n+1) + ' de ' +newIq.length + '...',
        needsAuth: true,
        silent: true
      }).then(function (d) {
        if (d.ok) {
          store.commit("patchUserData", d);
          store.commit("dequeueImageActa", { junta: jrv, convocatoria: convocatoria, serial: newIq[n].serial });
          subidas++;
        }
      });

      }

      alertController
        .create({
          message: (iq.length == subidas) ?  "Se subieron todas las imágenes pendientes." : ((subidas == 0) ? "No se pudieron subir las imágenes pendientes." : "Se subieron algunas de las imágenes pendientes, pero aún quedan algunas por subir."),
          buttons: ["OK"],
        })
        .then((alert) => alert.present());
      
    },
    verFotos(jrv: any, convocatoria: any) {
      const result = [];
      const medios = ud('estadoFoto')[jrv][convocatoria];
      if (!medios || !medios.length) return;
      let counter = 1;
      for (const i of medios) {
        result.push({
          src: i.url,
          title: "Imagen " + counter++ + " de " + medios.length,
        });
      }

      if (result.length) {
        store.commit("setLightboxImages", result);
        store.commit("setLightboxVisible", true);
      }
    },
  },
});
